












import { Cascader } from 'vant';
import { Vue, Component, PropSync, Emit } from 'vue-property-decorator';
// import { areaList } from '@vant/area-data';
import { getAddressAreaInfo } from '@/api/user/address';
import { clone } from 'lodash';

@Component({
  name: '',
  components: { Cascader }
})
export default class extends Vue {
  @PropSync('show')
  innerShow!: boolean;

  cascaderValue = '';
  tabIndex = 0;
  options: {
    text: string;
    value: string;
    children?: any[];
  }[] = [];

  async onChange({ selectedOptions, tabIndex }: any) {
    const arr = clone(selectedOptions);
    const option = arr.shift();
    this.tabIndex = tabIndex;
    this.deepOptions(arr, this.options, option);
  }
  @Emit('select-end')
  onFinish({ selectedOptions }: any) {
    this.innerShow = false;
    return selectedOptions;
  }

  async deepOptions(selectedOptions: this['options'], list: this['options'], option: this['options'][number]) {
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.value === option.value) {
        const nextOption = selectedOptions.shift();
        if (nextOption && option.children) {
          this.deepOptions(selectedOptions, option.children, nextOption);
        } else if (element.children) {
          this.$set(element, 'children', await this.getAddressAreaInfo(option.value));
        }
        return;
      }
    }
  }

  async getAddressAreaInfo(id: string) {
    const { payload } = await getAddressAreaInfo(id);
    return payload.map(item => {
      let obj: this['options'][number] = {
        text: item.areaName,
        value: item.areaCode
      };
      // 插入第四行不要children
      if (this.tabIndex < 1) {
        this.$set(obj, 'children', []);
      }
      return obj;
    });
  }

  async created() {
    this.options = await this.getAddressAreaInfo('100000');
  }
}
