
































import { AddressItem, deleteAddress, editAddress, getAddressById } from '@/api/user/address';
import { Vue, Component, Ref } from 'vue-property-decorator';
import { userPhone } from '@/utils/validate';
import { AddressOptionsEnum } from './address-list.vue';
import { setBuyAddress } from '@/utils/storage';
import { Cell, CellGroup, Form, Toast } from 'vant';
import AddressCascader from './comm/AddressCascader.vue';
@Component({
  name: '',
  components: { Cell, CellGroup, AddressCascader }
})
export default class extends Vue {
  get addressId() {
    return this.$route.query.id as string;
  }

  addressInfo = {} as AddressItem;

  isCascader = false;
  isDefaulted = false;
  /* 级联选择器结果 */
  addressCascaderValue = '';
  /* 手机号检验规则 */
  userPhone = userPhone('手机号');

  @Ref('myForm')
  myForm!: Form;

  onSelectEnd(data: any[]) {
    this.addressInfo.provinceCode = data[0].value;
    this.addressInfo.provinceName = data[0].text;

    this.addressInfo.cityCode = data[1].value;
    this.addressInfo.cityName = data[1].text;

    this.addressInfo.districtCode = data[2].value;
    this.addressInfo.districtName = data[2].text;

    // this.addressInfo.townCode = data[3].value;
    // this.addressInfo.townName = data[3].text;
    this.addressCascaderValue = [...new Set(data.map(option => option.text))].join('/');
  }

  async getAddress() {
    if (!this.addressId) {
      document.title = '新增地址';
      return;
    }
    const { payload } = await getAddressById(this.addressId);
    this.isDefaulted = Boolean(payload.defaulted);
    this.addressInfo = payload;
    this.addressCascaderValue = [...new Set([payload.provinceName, payload.cityName, payload.districtName, payload.townName])].join('/');
  }

  async onSave() {
    this.addressInfo.defaulted = Number(this.isDefaulted) as 0 | 1;
    const { payload } = await editAddress(this.addressInfo);
    this.$router.go(-1);
    if (this.$route.query.type === AddressOptionsEnum.CREATE_ORDER_SELECT) {
      setBuyAddress(payload);
    }
  }

  async onDelete() {
    await deleteAddress(this.addressId);
    this.$router.go(-1);
  }

  async handleClickConfirm() {
    try {
      await this.myForm.validate();
    } catch (e: any) {
      Toast(e[0].message);
    }

    this.onSave();
  }

  created() {
    this.getAddress();
  }
}
