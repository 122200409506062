// 常用校验

export type validateTrigger = 'onChange' | 'onBlur' | '' | ('onChange' | 'onBlur' | '')[];

export type ValidateRule = {
  required?: boolean;
  message?: string;
  pattern?: RegExp;
  trigger?: validateTrigger;
  validator?: (rule: ValidateRule, value: any, cb: (valid: any) => void) => void;
};

export type ValidateRules<T = any> = Record<keyof T, ValidateRule[]>;

export const patterns = {
  external: /^(https?:|mailto:|tel:)/,
  url: /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/,
  lowerCase: /^[a-z]+$/,
  upperCase: /^[A-Z]+$/,
  alphabets: /^[A-Za-z]+$/,
  chineseName: /[\u4e00-\u9fa5]{2,15}/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  idNumber: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|12]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|12]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
  // 使用这个网站的正则表达式 https://blog.csdn.net/to_study/article/details/106832942
  telephone: /^1(3([0-35-9]\d|4[1-8])|4[14-9]\d|5([0-35689]\d|7[1-79])|66\d|7[2-35-8]\d|8\d{2}|9[13589]\d)\d{7}$/,
  // 脱敏信息的正则表达式
  secret: /\*\*\*\*\*/,
  excel: /\.(xls|xlsx|csv)/,
  number: /^[+-]?\d+(\.\d+)?$/,
  fax: /^(\d{3,4}-)?\d{7,8}$/,
  zipCode: /^[1-9]\d{5}$/,
  userName: /^[\u4e00-\u9fa5a-zA-Z0-9_-]+$/,
  userAccount: /^[a-zA-Z0-9_-]+$/,
  password: /^[\w!@#$%^&*().\\/]+$/,
  // 正整数
  positiveInteger: /(^[1-9]\d*$)/
};

export const userPhone = (text = '') =>
  [
    { trigger: 'onBlur', required: true, message: text + '必填' },
    { trigger: 'onBlur', pattern: patterns.telephone, message: text + '格式错误' }
  ] as ValidateRule[];

export const userAccount = (text = '') =>
  [
    { required: true, trigger: 'onBlur', message: text + '必填' },
    { trigger: 'onBlur', min: 1, max: 50, message: text + '长度1到50位' },
    { trigger: 'onBlur', pattern: patterns.userAccount, message: text + '只能包含字母，数字，下划线，减号' }
  ] as ValidateRule[];

// 表单校验规则，用户密码规则
export const passwordRule = (text = '') =>
  [
    { required: true, trigger: 'onBlur', message: text + '密码必填' },
    { trigger: 'onBlur', min: 4, max: 15, message: text + '密码长度4到15位' },
    { trigger: 'onBlur', pattern: patterns.password, message: text + '密码只能包含字母、数字、英文符号' }
  ] as ValidateRule[];
